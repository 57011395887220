import { QueryClient, useSuspenseQuery } from "@tanstack/react-query"
import { Outlet, createRootRouteWithContext } from "@tanstack/react-router"
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
// import { TanStackRouterDevelopmentTools } from "@/components/TanStackRouterDevelopmentTools"
import { Suspense, useEffect } from "react"
import { useTranslation } from "react-i18next"
import AuthProvider from "@/components/AuthProvider"
import TMNProcessingLogo from "@/components/TMNProcessingLogo"
import { Toaster } from "@/components/ui/toaster"
import { initMixpanel } from "@/utils/MixpanelConfig"
import { initBraze } from "@/utils/BrazeConfig"
import { initFaro, faro } from "@/utils/FaroConfig"
import RequestError from "@/utils/RequestError"
import { AppConfigs } from "@/api/appConfigs.api"
import { profileQueryOptions } from "@/api/users.api"
import useAppConfigStore from "@/stores/useAppConfigStore"
import { ErrorPage } from "@/routes/_fullscreen-layout/error/route.lazy"
import { startSession } from "@/api/analytic.api"
import "@/utils/i18n"

const setAppConfig = ({
  appConfigs,
  setAppVersion,
  setAssetUrl,
  setMediaUrl,
  setIsEnableChallenge,
  setIsEnableMAExchange,
  setIsEnableMACoupon
}: {
  appConfigs: AppConfigs | undefined
  setAppVersion: (appVersion: string) => void
  setAssetUrl: (assetUrl: string) => void
  setMediaUrl: (mediaUrl: string) => void
  setIsEnableChallenge: (isEnableChallenge: boolean) => void
  setIsEnableMAExchange: (isEnableMAExchange: boolean) => void
  setIsEnableMACoupon: (isEnableMACoupon: boolean) => void
}) => {
  const faroToken = appConfigs?.faroToken
  if (faroToken) {
    initFaro(faroToken)
  }

  const mixpanelToken = appConfigs?.mixpanelToken
  if (mixpanelToken) {
    initMixpanel(mixpanelToken)
  }

  const brazeToken = appConfigs?.brazeToken
  if (brazeToken) {
    initBraze(brazeToken)
  }

  const version = appConfigs?.version
  if (version) {
    setAppVersion(version)
  }

  setAssetUrl(appConfigs?.assetUrl ?? "")
  setMediaUrl(appConfigs?.mediaUrl ?? "")
  setIsEnableChallenge(appConfigs?.isEnableChallenge ?? false)
  setIsEnableMAExchange(appConfigs?.isEnableMAExchange ?? false)
  setIsEnableMACoupon(appConfigs?.isEnableMACoupon ?? false)
}

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: RootComponent,
  loader: ({ context: { queryClient } }) =>
    queryClient?.ensureQueryData(profileQueryOptions),
  onError(err) {
    console.error("RootComponent onError:", err)
  },
  errorComponent: ({ error, info }) => {
    useEffect(() => {
      console.error("RootComponent errorComponent:", error, info)
    }, [error, info])

    if (error instanceof RequestError && error.errorCode && error.errorMessage) {
      console.error("RootComponent RequestError:", error)
      // Show error page with custom message when mixpanel is ready
      return <ErrorPage code={error.errorCode} message={error.errorMessage} />
    }
    // Show default error page when tracking is not ready
    // This mean the error page will not fire tracking event
    console.error("RootComponent Error Page:", error)
    return <ErrorPage />
  }
})

function RootComponent() {
  const {
    data: userProfile,
    isLoading: isUserProfileLoading,
    error: profileError
  } = useSuspenseQuery(profileQueryOptions)
  const {
    setAppVersion,
    setIsEnableChallenge,
    setIsEnableMAExchange,
    setIsEnableMACoupon,
    setAssetUrl,
    setMediaUrl,
    setUser
  } = useAppConfigStore()
  const { t } = useTranslation(["dashboard"])
  const appConfigs = window.APP_CONFIGS as AppConfigs
  useEffect(() => {
    setAppConfig({
      appConfigs,
      setAppVersion,
      setIsEnableChallenge,
      setIsEnableMAExchange,
      setIsEnableMACoupon,
      setAssetUrl,
      setMediaUrl
    })
    setUser(userProfile)
    startSession(userProfile?.id ?? "")
    if (faro) {
      faro.api.setUser({
        id: userProfile?.id ?? ""
      })
    }
  }, [userProfile])

  if (isUserProfileLoading) return null
  if (profileError) return <ErrorPage />

  return (
    // Importance!! This suspense is for the TanStackRouterDevelopmentTools and react-i18next to load the translation files(aka. namespaces)
    <Suspense fallback={<TMNProcessingLogo message={t("loading")} />}>
      <AuthProvider>
        <Outlet />
        {/* <ReactQueryDevtools buttonPosition="top-right" /> */}
        {/* <TanStackRouterDevelopmentTools position="bottom-right" /> */}
        <Toaster />
      </AuthProvider>
    </Suspense>
  )
}
