import { AsDeal, AsCaper, TokenType } from "@/api/coupon.api"
import { Coupon } from "../-components/CouponCard"
import { getCurrentLanguage } from "@/utils/i18n"

interface CouponData {
  owner: string
  contractAddress: string
  tokenId: string
  id: string
  tokenType: TokenType
  asDeal: AsDeal
  asCaper: AsCaper
  balance: number
  txHash?: string
}

export const mapCouponData = (data: CouponData, mediaUrl: string): Coupon => {
  const lang = getCurrentLanguage()

  const voucherMetadata = data?.asDeal?.metadata?.find(meta => meta.locale === lang)
  const cashbackMetadata = data?.asCaper?.metadata?.find(meta => meta.locale === lang)
  if (data.tokenType === TokenType.Deals) {
    return {
      owner: data?.owner ?? "",
      contractAddress: data?.contractAddress ?? "",
      tokenId: data?.tokenId ?? "",
      txHash: data?.txHash ?? "",
      balance: data?.balance ?? 0,
      metadata: {
        id: data?.id ?? "",
        name: voucherMetadata?.merchantName ?? "",
        description: voucherMetadata?.name ?? "",
        image: voucherMetadata?.bgPicture ?? "",
        type: data.tokenType,
        classification:
          data?.asDeal?.metadata?.find(meta => meta.locale === "en")?.categoryName ?? "",
        endDate: voucherMetadata?.endDateTime,
        attributes: voucherMetadata?.attributes
      }
    }
  } else {
    return {
      owner: data?.owner ?? "",
      contractAddress: data?.contractAddress ?? "",
      tokenId: data?.tokenId ?? "",
      balance: data?.balance ?? 0,
      metadata: {
        id: data?.id ?? "",
        name: cashbackMetadata?.name ?? "",
        description: cashbackMetadata?.shortDescription ?? "",
        image: `${mediaUrl}${cashbackMetadata?.image ?? ""}`,
        type: data.tokenType,
        classification: "Cashback"
      }
    }
  }
}
