import { useTranslation } from "react-i18next"
import dayjs from "dayjs"

//components
import { AspectRatio } from "@/components/ui/aspect-ratio"
import { Label } from "../../exchange/-components/ProductCard"

//APIs
import { TokenType } from "@/api/coupon.api"

//utils
import { Category } from "../-utils/enum"
import isOneOfLuckyDraw from "../-utils/isOneOfLuckyDraw"

const MAX_HOURS_BEFORE_EXPIRY = 72

// eslint-disable-next-line react-refresh/only-export-components
export enum CouponType {
  received = "received",
  used = "used",
  expired = "expired"
}

export interface Coupon {
  tokenId: string
  contractAddress: string
  owner: string
  balance: number
  txHash?: string
  metadata: {
    id: string
    name: string
    description: string
    image: string
    type: TokenType
    classification: string
    endDate?: string
    attributes?: {
      link: {
        rel: string
        href: string
        title: string
      }
    }
  }
}

interface Props {
  data: Coupon
  type: CouponType
  onClick?: () => void
}

const Balance = ({ id, balance }: { id: string; balance: number }) => {
  return (
    <div
      data-testid={`coupon-balance-${id}`}
      className="absolute -top-3.5 -right-1 bg-tmn-black-tint-10 rounded-sm rounded-br-none px-2"
    >
      <span className="body-strong text-white">X{balance}</span>
      <div className="absolute right-0 w-0 h-0 border-t-4 border-r-4 border-solid border-t-tmn-black-tint-10 border-r-transparent"></div>
    </div>
  )
}

const CouponCard = ({ data, type, onClick }: Props) => {
  const { t } = useTranslation(["coupon"])
  const {
    metadata: { id, name, description, image, classification, endDate, attributes },
    balance
  } = data

  const isLuckyDraw = isOneOfLuckyDraw(classification as Category)

  const currentDate = dayjs()
  const isExpirySoon =
    type === CouponType.received &&
    !isLuckyDraw &&
    dayjs(endDate).diff(currentDate, "hours") < MAX_HOURS_BEFORE_EXPIRY

  const getButtonInfo = () => {
    let info = {
      title: "",
      onClick
    }

    const isHidden =
      attributes?.link.rel === "hidden" ||
      (type === CouponType.received && isLuckyDraw) ||
      type === CouponType.expired ||
      type === CouponType.used
    const isCustom = attributes?.link.rel === "custom"

    if (isHidden) return info
    if (isCustom) {
      info = {
        title: attributes?.link.title,
        onClick: () => {
          if (onClick) {
            onClick()
          }
        }
      }

      return info
    }

    return {
      title: t("useCouponButton"),
      onClick
    }
  }

  const { title: buttonTitle, onClick: buttonOnClick } = getButtonInfo()

  return (
    <div
      data-testid={`coupon-card-container-${id}`}
      className={`relative tmn-card-shadow bg-white rounded-lg w-full cursor-pointer ${type === CouponType.expired ? "opacity-[.48]" : ""}`}
      onClick={buttonOnClick}
    >
      <AspectRatio ratio={4 / 3}>
        <img
          data-testid={`coupon-image-${id}`}
          className="w-full h-full rounded-t-lg"
          alt={name}
          src={image}
        />
      </AspectRatio>
      {isExpirySoon && (
        <div className="absolute top-2 right-2">
          <Label id={id} title={t("expirySoonLabel")} />
        </div>
      )}
      <div className="flex flex-col gap-2 p-2 relative">
        {balance > 1 && <Balance id={id} balance={balance} />}
        <div className="flex flex-col gap-1">
          <span
            data-testid={`coupon-title-text-${id}`}
            className="button-small text-tmn-black-tint-50 line-clamp-1 text-ellipsis"
          >
            {name}
          </span>
          <span
            data-testid={`coupon-desc-text-${id}`}
            className="body text-tmn-black-tint-10 h-10 line-clamp-2 text-ellipsis"
          >
            {description}
          </span>
        </div>
        {buttonTitle && (
          <button
            data-testid={`coupon-use-button-${id}`}
            className="button-small rounded-[100px] py-[5px] px-4 text-white bg-tmn-orange-tint-0 w-full"
            type="button"
          >
            {buttonTitle}
          </button>
        )}
      </div>
    </div>
  )
}

export default CouponCard
